import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HeaderTypography from "../v2/Typography/HeaderTypography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Avatar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import India from "../../images/v2/careers/India.svg";
import Singapore from "../../images/v2/careers/Singapore.svg";
import Malaysis from "../../images/v2/careers/Malaysis.svg";
import Phillpinaas from "../../images/v2/careers/Phillpinaas.svg";
import Mynamar from "../../images/v2/careers/Mynamar.svg";
import { bool } from "prop-types";

const useStyles = makeStyles((theme) => ({
	large: {
		width: 72,
		height: 72,
		margin: "0 22px 0 0",
	},
	root: {
		minWidth: 379,
		minHeight: 120,
		margin: "0 33px 35px 22.3px",
		padding: "24px 54.3px 27.1px 22.7px",
		borderRadius: "10px",
		boxShadow: "5px 12px 20px 0 rgba(0, 0, 0, 0.1)",
		backgroundColor: "#ffffff",
		[theme.breakpoints.down("md")]: {
			width: 332,
			margin: 0,
			minWidth: "unset",
			padding: "1rem",
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			margin: 0,
			minWidth: "unset",
			padding: "1rem",
		},
	},
}));

const OurOfficesData = [
	{
		title: "Singapore",
		description: "#34-04, Tower 1, One Raffles Place,\n" + "Singapore 048616.",
		logo: Singapore,
		alt: "Singapore CRM",
	},
	{
		title: "India",
		description: "2nd Floor, Salarpuria Tower 1, 7th Block \n" + "Koramangala, Bengaluru, \n" + "India - 560095.",
		logo: India,
		alt: "Pepper Cloud CRM - India",
	},
	{
		title: "Malaysia",
		description: "6th Floor, No, 3, Jalan SS 7/19, 47301\n" + "Petaling Jaya, Selangor, \n" + "Malaysia - 47301.",
		logo: Malaysis,
		alt: "Pepper Cloud CRM - Malaysia",
	},
	{
		title: "Philippines",
		description: "Centre, No. 47 Kamias Road, \n" + "Barangay Pinyahan, Quezon City, \n" + "Philippines - 1102",
		logo: Phillpinaas,
		alt: "Pepper Cloud CRM - Phillipines",
	},
	{
		title: "Myanmar",
		description: "No.22(A), Kabaraye Pagoda Road, \n" + "Bahan Township, Yangon, \n" + "Myanmar - 11201.",
		logo: Mynamar,
		alt: "Pepper Cloud CRM - Myanmar",
	},
];

function OurOffices({ showHeading }) {
	const classes = useStyles();

	return (
		<Box component={Container} mb={6}>
			{showHeading && (
				<Box alignItems="center" display="flex !important" flexDirection="column" py={3} textAlign="center">
					<HeaderTypography mb={0} mt={6} textAlign="center">
						Our Offices
					</HeaderTypography>
				</Box>
			)}
			<Grid container spacing={4}>
				{OurOfficesData.map((each) => (
					<Grid item key={each.title} md={4} sm={6} xs={12}>
						<Card className={classes.root}>
							<Box alignItems="center" display="flex" justifyContent="space-between">
								<Avatar alt={each.alt} className={classes.large} src={each.logo} />
								<Box width="100%">
									<Box component="h3" fontSize="20px" mt={0} mb={1} fontWeight="600">
										{each.title}
									</Box>
									<Box color="#2e3f4f" fontSize="14px" fontWeight="600" letterSpacing="normal" lineHeight="1.36">
										{each.description}
									</Box>
								</Box>
							</Box>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

OurOffices.propTypes = {
	showHeading: bool,
};

OurOffices.defaultProps = {
	showHeading: true,
};

export default OurOffices;
